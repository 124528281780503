import { all, fork, put, takeEvery } from 'redux-saga/effects';
import moment from 'moment';
import { stardardRequest } from '../../utils';
import { API_BASE } from '../../constants/api.constant'

import {
  TOWERS_FETCH,
  USERS_FETCH,
  USERS_FETCH_BY_ID,
  USERS_CREATE,
  USERS_EDIT,
  USERS_ACTIVE_TOGGLE,
  USERS_ROLES_SET,
  SITES_CRUD_SUBMIT,
  CLIENTS_CRUD_SUBMIT,
  TOWERS_CRUD_SUBMIT,
  TOWERS_RELAY_CRUD_SUBMIT,
  CAMERAS_FETCH,
  CAMERAS_CRUD_SUBMIT,
  CAMERAS_CONFIG_CHECK,
  TOWERS_CONFIG_CHECK,
  TOWERS_W3W_CHECK,
  CLIENTS_DELETE,
  SITES_DELETE,
  TOWERS_DELETE,
  BUILDS_FETCH,
  USERS_SET_FILTERS,
  USERS_SET_SEARCH,
  USERS_ACTIVITY,
  USERS_RESET_PASS_LINK,
  USERS_RESET_PASS_SEND
} from '../actions';

export function* watchTowersFetchRequest() {
  const action = TOWERS_FETCH
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'get',
      url: `${API_BASE}/v1/towers/fetch`
    },
    dataPath: 'result',
    dataDefault: [],
    beforeSuccess: (data, state, payload) => {
      data.sites = data.sites.map(d => {
        d.created_at = moment(d.created_at).format('DD/MM/YYYY HH:mm:ss')
        d.updated_at = moment(d.updated_at).format('DD/MM/YYYY HH:mm:ss')
        return d
      })
      data.clients = data.clients.map(d => {
        d.created_at = moment(d.created_at).format('DD/MM/YYYY HH:mm:ss')
        d.updated_at = moment(d.updated_at).format('DD/MM/YYYY HH:mm:ss')
        return d
      })
      data.towers = data.towers.map(d => {
        d.site.created_at = moment(d.site.created_at).format('DD/MM/YYYY HH:mm:ss')
        d.site.updated_at = moment(d.site.updated_at).format('DD/MM/YYYY HH:mm:ss')
        d.created_at = moment(d.created_at).format('DD/MM/YYYY HH:mm:ss')
        d.updated_at = moment(d.updated_at).format('DD/MM/YYYY HH:mm:ss')
        return d
      })
      return data
    }
  }));
}

export function* watchSitesCrudSubmitRequest() {
  const action = SITES_CRUD_SUBMIT
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: payload.id ? 'patch' : 'post',
        url: `${API_BASE}/v1/sites`,
        data: payload
      }
    },
    dataPath: 'result',
    dataDefault: null
  }));
}

export function* watchSitesCrudDeleteRequest() {
  const action = SITES_DELETE
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'delete',
        url: `${API_BASE}/v1/sites`,
        data: payload
      }
    },
    dataPath: 'result',
    dataDefault: null
  }));
}

export function* watchClientsCrudSubmitRequest() {
  const action = CLIENTS_CRUD_SUBMIT
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: payload.id ? 'patch' : 'post',
        url: `${API_BASE}/v1/clients`,
        data: payload
      }
    },
    dataPath: 'result',
    dataDefault: null
  }));
}

export function* watchClientsCrudDeleteRequest() {
  const action = CLIENTS_DELETE
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'delete',
        url: `${API_BASE}/v1/clients`,
        data: payload
      }
    },
    dataPath: 'result',
    dataDefault: null
  }));
}

export function* watchTowersCrudSubmitRequest() {
  const action = TOWERS_CRUD_SUBMIT
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: payload.id ? 'patch' : 'post',
        url: `${API_BASE}/v1/towers`,
        data: payload
      }
    },
    dataPath: 'result',
    dataDefault: null
  }));
}

export function* watchTowersCrudDeleteRequest() {
  const action = TOWERS_DELETE
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'delete',
        url: `${API_BASE}/v1/towers`,
        data: payload
      }
    },
    dataPath: 'result',
    dataDefault: null
  }));
}

export function* watchTowersRelaySubmitRequest() {
  const action = TOWERS_RELAY_CRUD_SUBMIT
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'post',
        url: `${API_BASE}/v1/towers/relay`,
        data: payload
      }
    },
    dataPath: 'result',
    dataDefault: null
  }));
}

export function* watchCameraFetchRequest() {
  const action = CAMERAS_FETCH
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'get',
        url: `${API_BASE}/v1/cameras/fetch/${payload._id}`,
        data: payload
      }
    },
    dataPath: 'cameras',
    dataDefault: null
  }));
}

export function* watchCameraSubmitRequest() {
  const action = CAMERAS_CRUD_SUBMIT
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: payload.id ? 'patch' : 'post',
        url: `${API_BASE}/v1/cameras`,
        data: payload
      }
    },
    dataPath: 'result',
    dataDefault: null
  }));
}

export function* watchTowerConfigCheckRequest() {
  const action = TOWERS_CONFIG_CHECK
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'post',
        url: `${API_BASE}/v1/towers/check-config`,
        data: payload
      }
    },
    dataPath: 'result',
    dataDefault: null
  }));
}

export function* watchCameraConfigCheckRequest() {
  const action = CAMERAS_CONFIG_CHECK
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'post',
        url: `${API_BASE}/v1/cameras/check-config`,
        data: payload
      }
    },
    dataPath: 'result',
    dataDefault: null
  }));
}

export function* watchUsersFetchRequest() {
  const action = USERS_FETCH
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload, state) => {
      const { towers: { grid_options: { users: { filters, search, sort } } } }  = state
      delete filters.site
      return {
        method: 'post',
        url: `${API_BASE}/v1/admin/auth/users/fetch`,
        data: {
          filters, search, sort
        }
      }
    },
    dataPath: 'users',
    dataDefault: [],
  }));
}

export function* watchUsersFetchIdRequest() {
  const action = USERS_FETCH_BY_ID
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'get',
        url: `${API_BASE}/v1/admin/auth/users/fetch/${payload.id}`
      }
    },
    dataPath: 'user',
    dataDefault: null,
  }));
}

export function* watchUsersSearchRequest() {
  yield takeEvery(USERS_SET_SEARCH.REQUEST, function* ({ payload }) {
    console.log('watchUsersSearchRequest')
    yield put(USERS_FETCH.requestAction({}))
  })
}

export function* watchUsersFiltersRequest() {
  yield takeEvery(USERS_SET_FILTERS.REQUEST, function* ({ payload }) {
    console.log('watchUsersFiltersRequest')
    yield put(USERS_FETCH.requestAction({}))
  })
}

export function* watchUsersCreateRequest() {
  const action = USERS_CREATE
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'post',
      url: `${API_BASE}/v1/admin/auth/user/create`
    },
    requestOptionsFromPayload: (payload) => {
      return {
        data: payload
      }
    },
    dataPath: 'users',
    dataDefault: [],
  }));
}

export function* watchUsersEditRequest() {
  const action = USERS_EDIT
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'patch',
      url: `${API_BASE}/v1/admin/auth/user/edit`
    },
    requestOptionsFromPayload: (payload) => {
      return {
        data: payload
      }
    },
    dataPath: 'users',
    dataDefault: [],
  }));
}

export function* watchUsersActiveToggleRequest() {
  const action = USERS_ACTIVE_TOGGLE
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'post'
    },
    requestOptionsFromPayload: (payload) => {
      return {
        url: `${API_BASE}/v1/admin/auth/user/deleted/${payload.id}`,
        data: {
          ...payload,
          id: undefined
        }
      }
    },
    dataPath: 'user',
    dataDefault: [],
  }));
}

export function* watchUsersRolesSetRequest() {
  const action = USERS_ROLES_SET
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'post'
    },
    requestOptionsFromPayload: (payload) => {
      return {
        url: `${API_BASE}/v1/admin/auth/user/roles/${payload.id}`,
        data: {
          ...payload,
          id: undefined
        }
      }
    },
    dataPath: 'user',
    dataDefault: [],
  }));
}

export function* watchUsersResetPasswordLinkRequest() {
  const action = USERS_RESET_PASS_LINK
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'post'
    },
    requestOptionsFromPayload: (payload) => {
      return {
        url: `${API_BASE}/v1/admin/auth/user/reset-password/link/${payload.id}`,
        data: {}
      }
    },
    dataPath: 'result',
    dataDefault: null,
  }));
}

export function* watchUsersResetPasswordSendRequest() {
  const action = USERS_RESET_PASS_SEND
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'post'
    },
    requestOptionsFromPayload: (payload) => {
      return {
        url: `${API_BASE}/v1/admin/auth/user/reset-password/send/${payload.id}`,
        data: {}
      }
    },
    dataPath: 'result',
    dataDefault: null,
  }));
}

export function* watchW3wRequest() {
  const action = TOWERS_W3W_CHECK
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'post'
    },
    requestOptionsFromPayload: (payload) => {
      return {
        url: `${API_BASE}/v1/towers/w3w`,
        data: payload
      }
    },
    dataPath: 'result',
    dataDefault: [],
  }));
}

export function* watchWBuildsFetchRequest() {
  const action = BUILDS_FETCH
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'get'
    },
    requestOptionsFromPayload: (payload) => {
      return {
        url: `${API_BASE}/v1/builds/fetch`,
      }
    },
    dataPath: 'builds',
    dataDefault: [],
  }));
}

export function* watchWUserActivityFetchRequest() {
  const action = USERS_ACTIVITY
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'post'
    },
    requestOptionsFromPayload: (payload) => {
      return {
        url: `${API_BASE}/v1/admin/auth/user/activity/${payload.id}`,
      }
    },
    dataPath: 'activity',
    dataDefault: [],
  }));
}

export default function* rootSaga() {
  yield all([
    fork(watchSitesCrudSubmitRequest),
    fork(watchClientsCrudSubmitRequest),
    fork(watchTowersCrudSubmitRequest),
    fork(watchTowersFetchRequest),
    fork(watchTowersRelaySubmitRequest),
    fork(watchUsersFetchRequest),
    fork(watchUsersFetchRequest),
    fork(watchUsersSearchRequest),
    fork(watchUsersFiltersRequest),
    fork(watchUsersFetchIdRequest),
    fork(watchUsersCreateRequest),
    fork(watchUsersEditRequest),
    fork(watchUsersActiveToggleRequest),
    fork(watchCameraFetchRequest),
    fork(watchCameraSubmitRequest),
    fork(watchCameraConfigCheckRequest),
    fork(watchTowerConfigCheckRequest),
    fork(watchClientsCrudDeleteRequest),
    fork(watchSitesCrudDeleteRequest),
    fork(watchTowersCrudDeleteRequest),
    fork(watchW3wRequest),
    fork(watchWBuildsFetchRequest),
    fork(watchUsersRolesSetRequest),
    fork(watchWUserActivityFetchRequest),
    fork(watchUsersResetPasswordSendRequest),
    fork(watchUsersResetPasswordLinkRequest),
  ]);
}