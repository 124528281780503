import { REHYDRATE } from 'redux-persist';
import {
  ORGS_FETCH,
  ORGS_FETCH_BY_ID,
  ORGS_FETCH_SOURCE_BY_ID,
  ORGS_FETCH_CLEAR,
  ORGS_SET_SEARCH,
  ORGS_SET_SORT,
  ORGS_SET_VIEW,
  ORGS_SET_FILTERS,
  ORGS_CRUD_MODE,
  ORGS_OPTIONS_PARENT_PROGRAMME,
  ORGS_OPTIONS_PARSER
} from '../actions';

const INIT_STATE = {
  list: [],
  select_options: { parents: [], programmes: [], parsers: [] },
  org: null,
  grid_options: {
    orgs: {
      crud_modal: null,
      crud_data: null,
      view: 'list',
      sort: 'asc',
      search: null,
      filters: {}
    }
  }
};

const getKey = (action) => {
  let key = null
  const allKeys = ['orgs']
  if (action.type.indexOf('ORGS_') === 0) {
    key = 'orgs'
  }
  return { key, remaining: allKeys.filter(a => a !== key) }
}

const handler = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ORGS_FETCH.REQUEST: {
      return { ...state, list: [] }
    }
    case ORGS_FETCH.SUCCESS: {
      return { ...state, list: action.payload }
    }
    case ORGS_FETCH_BY_ID.REQUEST:
    case ORGS_FETCH_SOURCE_BY_ID.REQUEST:
    case ORGS_FETCH_CLEAR.REQUEST: {
      return { ...state, org: null }
    }
    case ORGS_FETCH_BY_ID.SUCCESS:
    case ORGS_FETCH_SOURCE_BY_ID.SUCCESS: {
      return { ...state, org: action.payload }
    }
    case ORGS_SET_SEARCH.REQUEST: {
      const { key } = getKey(action)
      return { ...state, grid_options: {
        ...state.grid_options,
        [key]: {
          ...state.grid_options[key],
          search: action.payload.query
        }
      } }
    }
    case ORGS_SET_SORT.REQUEST: {
      const { key } = getKey(action)
      return { ...state, grid_options: {
        ...state.grid_options,
        [key]: {
          ...state.grid_options[key],
          sort: action.payload
        }
      } }
    }
    case ORGS_SET_VIEW.REQUEST: {
      const { key } = getKey(action)
      return { ...state, grid_options: {
        ...state.grid_options,
        [key]: {
          ...state.grid_options[key],
          view: action.payload
        }
      } }
    }
    case ORGS_SET_FILTERS.REQUEST: {
      const { key, remaining: [remKey1, remKey2, remKey3] } = getKey(action)
      return { ...state, grid_options: {
        ...state.grid_options,
        [key]: {
          ...state.grid_options[key],
          filters: action.payload
        },
        [remKey1]: {
          ...state.grid_options[key],
          filters: {}
        },
        [remKey2]: {
          ...state.grid_options[key],
          filters: {}
        },
        [remKey3]: {
          ...state.grid_options[key],
          filters: {}
        },
      } }
    }
    case ORGS_CRUD_MODE.REQUEST: {
      const { key } = getKey(action)
      const { mode, data } = action.payload
      return { ...state, grid_options: {
        ...state.grid_options,
        [key]: {
          ...state.grid_options[key],
          crud_modal: mode,
          crud_data: data||null
        }
      } }
    }
    case ORGS_OPTIONS_PARENT_PROGRAMME.REQUEST:
      return { ...state, select_options: {
        ...state.select_options,
        parents: [],
        programmes: []
      } }      
    case ORGS_OPTIONS_PARSER.REQUEST:
      return { ...state, select_options: {
        ...state.select_options,
        parsers: [],
      } }      
    case ORGS_OPTIONS_PARENT_PROGRAMME.SUCCESS: {
      const { parents, programmes } = action.payload
      return { ...state, select_options: {
        ...state.select_options,
        parents,
        programmes 
      } }
    }
    case ORGS_OPTIONS_PARSER.SUCCESS: {
      const { parsers } = action.payload
      return { ...state, select_options: {
        ...state.select_options,
        parsers
      } }
    }
    case REHYDRATE: {
      return INIT_STATE
    }
    default: return { ...state };
  }
}

export default handler
