import { initializeApp } from "firebase/app";
import {
  getAuth,
} from 'firebase/auth'
import axios from 'axios'
axios.defaults.headers.common['x-auth-strategy'] = 'firebase';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBHYfP5YCQkgN0g0juwxH2JL5FIz6soa1A",
  authDomain: "analytics-df8f4.firebaseapp.com",
  projectId: "analytics-df8f4",
  storageBucket: "analytics-df8f4.appspot.com",
  messagingSenderId: "976478823323",
  appId: "1:976478823323:web:db7f4f83978bac20fff73e",
  measurementId: "G-LFF41KWLN1"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = () => {
  return getAuth(app)
}

export default auth