import { REHYDRATE } from 'redux-persist';
import {
  GROUPS_FETCH,
  GROUPS_SET_SEARCH,
  GROUPS_SET_SORT,
  GROUPS_SET_VIEW,
  GROUPS_SET_FILTERS,
  GROUPS_CRUD_MODE
} from '../actions';

const INIT_STATE = {
  list: [],
  grid_options: {
    groups: {
      crud_modal: null,
      crud_data: null,
      view: 'list',
      sort: 'asc',
      search: null,
      filters: {}
    }
  }
};

const getKey = (action) => {
  let key = null
  const allKeys = ['groups']
  if (action.type.indexOf('GROUPS_') === 0) {
    key = 'groups'
  }
  return { key, remaining: allKeys.filter(a => a !== key) }
}

const handler = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GROUPS_FETCH.REQUEST: {
      return { ...state, list: [] }
    }
    case GROUPS_FETCH.SUCCESS: {
      return { ...state, list: action.payload }
    }
    case GROUPS_SET_SEARCH.REQUEST: {
      const { key } = getKey(action)
      return { ...state, grid_options: {
        ...state.grid_options,
        [key]: {
          ...state.grid_options[key],
          search: action.payload.query
        }
      } }
    }
    case GROUPS_SET_SORT.REQUEST: {
      const { key } = getKey(action)
      return { ...state, grid_options: {
        ...state.grid_options,
        [key]: {
          ...state.grid_options[key],
          sort: action.payload
        }
      } }
    }
    case GROUPS_SET_VIEW.REQUEST: {
      const { key } = getKey(action)
      return { ...state, grid_options: {
        ...state.grid_options,
        [key]: {
          ...state.grid_options[key],
          view: action.payload
        }
      } }
    }
    case GROUPS_SET_FILTERS.REQUEST: {
      const { key, remaining: [remKey1, remKey2, remKey3] } = getKey(action)
      return { ...state, grid_options: {
        ...state.grid_options,
        [key]: {
          ...state.grid_options[key],
          filters: action.payload
        },
        [remKey1]: {
          ...state.grid_options[key],
          filters: {}
        },
        [remKey2]: {
          ...state.grid_options[key],
          filters: {}
        },
        [remKey3]: {
          ...state.grid_options[key],
          filters: {}
        },
      } }
    }
    case GROUPS_CRUD_MODE.REQUEST: {
      const { key } = getKey(action)
      const { mode, data } = action.payload
      return { ...state, grid_options: {
        ...state.grid_options,
        [key]: {
          ...state.grid_options[key],
          crud_modal: mode,
          crud_data: data||null
        }
      } }
    }
    case REHYDRATE: {
      return INIT_STATE
    }
    default: return { ...state };
  }
}

export default handler
