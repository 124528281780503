import { all, fork, takeEvery } from 'redux-saga/effects';
import { stardardRequest } from '../../utils';
import { API_BASE } from '../../constants/api.constant'

import {
  GROUPS_FETCH,
  GROUPS_CREATE,
  GROUPS_EDIT,
  GROUPS_ACTIVE_TOGGLE
} from '../actions';

export function* watchGroupsFetchRequest() {
  const action = GROUPS_FETCH
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'get',
      url: `${API_BASE}/v1/admin/groups/fetch`
    },
    dataPath: 'groups',
    dataDefault: [],
  }));
}

export function* watchGroupsCreateRequest() {
  const action = GROUPS_CREATE
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'post',
      url: `${API_BASE}/v1/admin/groups/create`
    },
    requestOptionsFromPayload: (payload) => {
      return {
        data: payload
      }
    },
    dataPath: 'groups',
    dataDefault: [],
  }));
}

export function* watchGroupsEditRequest() {
  const action = GROUPS_EDIT
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'patch',
      url: `${API_BASE}/v1/admin/groups/edit`
    },
    requestOptionsFromPayload: (payload) => {
      return {
        data: payload
      }
    },
    dataPath: 'groups',
    dataDefault: [],
  }));
}

export function* watchGroupsActiveToggleRequest() {
  const action = GROUPS_ACTIVE_TOGGLE
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'post'
    },
    requestOptionsFromPayload: (payload) => {
      return {
        url: `${API_BASE}/v1/admin/groups/deleted/${payload.id}`,
        data: {
          ...payload,
          id: undefined
        }
      }
    },
    dataPath: 'group',
    dataDefault: [],
  }));
}

export default function* rootSaga() {
  yield all([
    fork(watchGroupsFetchRequest),
    fork(watchGroupsCreateRequest),
    fork(watchGroupsEditRequest),
    fork(watchGroupsActiveToggleRequest),
  ]);
}