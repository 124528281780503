import { useMemo } from 'react'
import isEmpty from 'lodash/isEmpty'

function useAuthority(userAuthority = [], authority = [], emptyCheck = false) {

	const roleMatched = useMemo(() => {
		return authority.some(role => userAuthority.includes(role))
	}, [authority, userAuthority])

	// console.log(authority, 'authority2')
	// console.log(userAuthority, 'userAuthority2')
	// console.log(roleMatched, 'roleMatched2')
    
	if (isEmpty(authority) || isEmpty(userAuthority) || typeof authority === 'undefined') {
		return !emptyCheck
	}

	return roleMatched
}

export default useAuthority