import { REHYDRATE } from 'redux-persist';
import {
  SITES_SET_SEARCH,
  SITES_SET_SORT,
  SITES_SET_VIEW,
  SITES_SET_FILTERS,
  CLIENTS_SET_SEARCH,
  CLIENTS_SET_SORT,
  CLIENTS_SET_VIEW,
  CLIENTS_SET_FILTERS,
  USERS_FETCH,
  USERS_FETCH_CLEAR,
  USERS_FETCH_BY_ID,
  USERS_SET_SEARCH,
  USERS_SET_SORT,
  USERS_SET_VIEW,
  USERS_SET_FILTERS,
  USERS_CRUD_MODE,
  TOWERS_FETCH,
  TOWERS_SET_SEARCH,
  TOWERS_SET_SORT,
  TOWERS_SET_VIEW,
  TOWERS_SET_FILTERS,
  USERS_ACTIVITY,
} from '../actions';

const INIT_STATE = {
  sites: [],
  clients: [],
  towers: [],
  user: null,
  users: [],
  cameras: [],
  builds: [],
  camera_check: null,
  activity: [],
  grid_options: {
    sites: {
      crud_modal: null,
      crud_data: null,
      view: 'list',
      sort: 'asc',
      search: null,
      filters: {}
    },
    clients: {
      crud_modal: null,
      crud_data: null,
      view: 'list',
      sort: 'asc',
      search: null,
      filters: {}
    },
    towers: {
      crud_modal: null,
      crud_data: null,
      view: 'list',
      sort: 'asc',
      search: null,
      filters: {}
    },
    relays: {
      crud_modal: null,
      crud_data: null
    },
    cameras: {
      crud_modal: null,
      crud_data: null
    },
    users: {
      crud_modal: null,
      crud_data: null,
      view: 'list',
      sort: 'asc',
      search: null,
      filters: {}
    },
    builds: {
      crud_modal: null,
      crud_data: null,
      view: 'list',
      sort: 'asc',
      search: null,
      filters: {}
    },
  }
};

const getKey = (action) => {
  let key = null
  const allKeys = ['relays', 'towers', 'clients', 'sites', 'cameras', 'builds']
  if (action.type.indexOf('TOWERS_CAMERA_') === 0) {
    key = 'cameras'
  } else if (action.type.indexOf('TOWERS_RELAY_') === 0) {
    key = 'relays'
  } else if (action.type.indexOf('TOWERS_') === 0) {
    key = 'towers'
  } else if (action.type.indexOf('CLIENTS_') === 0) {
    key = 'clients'
  } else if (action.type.indexOf('SITES_') === 0) {
    key = 'sites'
  } else if (action.type.indexOf('USERS_') === 0) {
    key = 'users'
  } else if (action.type.indexOf('BUILDS_') === 0) {
    key = 'builds'
  }
  return { key, remaining: allKeys.filter(a => a !== key) }
}

const handler = (state = INIT_STATE, action) => {
  switch (action.type) {
    case TOWERS_FETCH.REQUEST: {
      return { ...state, sites: [], clients: [], towers: [] }
    }
    case TOWERS_FETCH.SUCCESS: {
      const { sites, clients, towers } = action.payload
      return { ...state, sites, clients, towers }
    }
    case USERS_FETCH.REQUEST:
    case USERS_FETCH_CLEAR.REQUEST: {
      return { ...state, users: [] }
    }
    case USERS_FETCH.SUCCESS: {
      return { ...state, users: action.payload }
    }
    case USERS_ACTIVITY.REQUEST: {
      return { ...state, activity: [] }
    }
    case USERS_ACTIVITY.SUCCESS: {
      return { ...state, activity: action.payload }
    }
    case USERS_FETCH_BY_ID.REQUEST: {
      return { ...state, user: null }
    }
    case USERS_FETCH_BY_ID.SUCCESS: {
      return { ...state, user: action.payload }
    }
    case SITES_SET_SEARCH.REQUEST:
    case CLIENTS_SET_SEARCH.REQUEST:
    case TOWERS_SET_SEARCH.REQUEST: 
    case USERS_SET_SEARCH.REQUEST: {
      const { key } = getKey(action)
      return { ...state, grid_options: {
        ...state.grid_options,
        [key]: {
          ...state.grid_options[key],
          search: action.payload.query
        }
      } }
    }
    case SITES_SET_SORT.REQUEST:
    case CLIENTS_SET_SORT.REQUEST:
    case TOWERS_SET_SORT.REQUEST: 
    case USERS_SET_SORT.REQUEST: {
      const { key } = getKey(action)
      return { ...state, grid_options: {
        ...state.grid_options,
        [key]: {
          ...state.grid_options[key],
          sort: action.payload
        }
      } }
    }
    case SITES_SET_VIEW.REQUEST:
    case CLIENTS_SET_VIEW.REQUEST:
    case TOWERS_SET_VIEW.REQUEST:
    case USERS_SET_VIEW.REQUEST: {
      const { key } = getKey(action)
      return { ...state, grid_options: {
        ...state.grid_options,
        [key]: {
          ...state.grid_options[key],
          view: action.payload
        }
      } }
    }
    case SITES_SET_FILTERS.REQUEST:
    case CLIENTS_SET_FILTERS.REQUEST:
    case TOWERS_SET_FILTERS.REQUEST:
    case USERS_SET_FILTERS.REQUEST: {
      const { key, remaining: [remKey1, remKey2, remKey3] } = getKey(action)
      return { ...state, grid_options: {
        ...state.grid_options,
        [key]: {
          ...state.grid_options[key],
          filters: {...state.grid_options[key].filters, ...action.payload}
        },
        [remKey1]: {
          ...state.grid_options[key],
          filters: {}
        },
        [remKey2]: {
          ...state.grid_options[key],
          filters: {}
        },
        [remKey3]: {
          ...state.grid_options[key],
          filters: {}
        },
      } }
    }
    case USERS_CRUD_MODE.REQUEST: {
      const { key } = getKey(action)
      const { mode, data } = action.payload
      return { ...state, grid_options: {
        ...state.grid_options,
        [key]: {
          ...state.grid_options[key],
          crud_modal: mode,
          crud_data: data||null
        }
      } }
    }
    case REHYDRATE: {
      return INIT_STATE
    }
    default: return { ...state };
  }
}

export default handler
