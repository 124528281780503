import { useSelector, useDispatch } from 'react-redux'
import { apiSignUp } from 'services/AuthService'
import { firebaseAuth, loginUserSuccess, logoutUser } from 'redux/actions'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import useQuery from './useQuery'
import { API_BASE } from '../../constants/api.constant'

// import auth from '@react-native-firebase/auth'
import {
	browserLocalPersistence,
	setPersistence,
	signInWithEmailAndPassword
} from 'firebase/auth'

import auth from '../auth';

function useAuth() {

	const dispatch = useDispatch()

	const navigate = useNavigate()

	const query = useQuery()

	const { token } = useSelector((state) => state.auth)

	const userPassLogin = async ({ userName, password }) => {
		await setPersistence(auth(), browserLocalPersistence)
		await signInWithEmailAndPassword(auth(), userName, password)
		const token = await auth().currentUser.getIdToken()
		return token
	}

	const authStateChanged = () => {
		console.log('useAuth -> authStateChanged')
		auth().onAuthStateChanged(async user => {
			if (!user) {
				handleSignOut()
			} else {
				const newToken = await user.getIdToken()
				if (token !== newToken) {
					dispatch(firebaseAuth({ token: newToken }))
				}
			}
		});
	}

	const signIn = async (values) => {
		try {
			const token = await userPassLogin(values)
			const resp = await axios.get(`${API_BASE}/v1/auth/current-user`, {
				headers: { Authorization: `Bearer ${token}` }
			})
			if (resp.data) {
				const { username, first_name, last_name, user_id: _id, roles } = resp.data
				let initials = ''
				if (first_name && first_name.length > 0) {
					initials += first_name[0]
				}
				if (last_name && last_name.length > 0) {
					initials += last_name[0]
				}
				let name = `${first_name} ${last_name}`.trim()
				if (initials.length === 0 && username && username.length > 0) {
					initials += username[0]
					name = username
				}
				initials = initials.toUpperCase()
				dispatch(loginUserSuccess({
					user: {
						_id,
						token,
						username,
						initials,
						name,
						first_name,
						last_name,
						profile_picture: '',
						roles
					}
				}))

				const entryPath = roles.includes('admin') || roles.includes('backend') ? appConfig.authenticatedEntryPath : appConfig.authenticatedEntryPathStandard
				const redirectUrl = query.get(REDIRECT_URL_KEY)
				const url = redirectUrl ? redirectUrl : entryPath

				navigate(url)
				// window.location = url
				return {
					status: 'success',
					message: ''
				}
			}
		} catch (errors) {
			return {
				status: 'failed',
				message: errors?.response?.data?.message || errors.toString()
			}
		}
	}

	const signUp = async (values) => {
		try {
			const resp = await apiSignUp(values)
			if (resp.data) {
				// const { token } = resp.data
				// dispatch(onSignInSuccess(token))
				if (resp.data.user) {
					// dispatch(loginUserSuccess({
					// 	user: {
					// 		_id: 1,
					// 		username: '',
					// 		name: 'Anton',
					// 		profile_picture: '',
					// 		roles: ['admin']
					// 	}
					// }))
					// dispatch(firebaseAuth({
					// 	token: 'hello'
					// }))
				}
				const redirectUrl = query.get(REDIRECT_URL_KEY)
				navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
				return {
					status: 'success',
					message: ''
				}
			}
		} catch (errors) {
			return {
				status: 'failed',
				message: errors?.response?.data?.message || errors.toString()
			}
		}
	}

	const handleSignOut = async () => {
		await auth().signOut()
		dispatch(logoutUser())
		if (window.location.pathname !== '/reset-password') {
			navigate(appConfig.unAuthenticatedEntryPath)
		}
	}

	const signOut = async () => {
		handleSignOut()
	}

	return {
		authenticated: token,
		authStateChanged,
		signIn,
		signUp,
		signOut
	}
}

export default useAuth