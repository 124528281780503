import { all, fork, takeEvery } from 'redux-saga/effects';
import { stardardRequest } from '../../utils';
import { API_BASE } from '../../constants/api.constant'

import {
  ORGS_FETCH,
  ORGS_FETCH_BY_ID,
  ORGS_FETCH_SOURCE_BY_ID,
  ORGS_CREATE,
  ORGS_EDIT,
  ORGS_OPTIONS_PARENT_PROGRAMME,
  ORGS_OPTIONS_PARSER,
  ORGS_ACTIVE_TOGGLE
} from '../actions';

export function* watchOrgsFetchRequest() {
  const action = ORGS_FETCH
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'get',
      url: `${API_BASE}/v1/admin/orgs/fetch`
    },
    dataPath: 'orgs',
    dataDefault: [],
  }));
}

export function* watchOrgsFetchIdRequest() {
  const action = ORGS_FETCH_BY_ID
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'get',
        url: `${API_BASE}/v1/admin/orgs/fetch/${payload.id}`
      }
    },
    dataPath: 'org',
    dataDefault: null,
  }));
}

export function* watchOrgsFetchSourceIdRequest() {
  const action = ORGS_FETCH_SOURCE_BY_ID
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'get',
        url: `${API_BASE}/v1/admin/orgs/fetch/${payload.source}/${payload.id}`
      }
    },
    dataPath: 'org',
    dataDefault: null,
  }));
}

export function* watchOrgsCreateRequest() {
  const action = ORGS_CREATE
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'post',
      url: `${API_BASE}/v1/admin/orgs/create`
    },
    requestOptionsFromPayload: (payload) => {
      return {
        data: payload
      }
    },
    dataPath: 'orgs',
    dataDefault: [],
  }));
}

export function* watchOrgsEditRequest() {
  const action = ORGS_EDIT
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'patch',
      url: `${API_BASE}/v1/admin/orgs/edit`
    },
    requestOptionsFromPayload: (payload) => {
      return {
        data: payload
      }
    },
    dataPath: 'orgs',
    dataDefault: [],
  }));
}

export function* watchOrgsActiveToggleRequest() {
  const action = ORGS_ACTIVE_TOGGLE
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'post'
    },
    requestOptionsFromPayload: (payload) => {
      return {
        url: `${API_BASE}/v1/admin/orgs/deleted/${payload.id}`,
        data: {
          ...payload,
          id: undefined
        }
      }
    },
    dataPath: 'result',
    dataDefault: [],
  }));
}

export function* watchOrgsOptionsParentProgrammeRequest() {
  const action = ORGS_OPTIONS_PARENT_PROGRAMME
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        url: `${API_BASE}/v1/admin/orgs/options/parent-programme/${payload.source}`,
        method: 'get'
      }
    },
    dataPath: 'result',
    dataDefault: [],
  }));
}

export function* watchOrgsOptionsParserRequest() {
  const action = ORGS_OPTIONS_PARSER
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        url: `${API_BASE}/v1/admin/orgs/options/parser/${payload.source}`,
        method: 'get'
      }
    },
    dataPath: 'result',
    dataDefault: [],
  }));
}

export default function* rootSaga() {
  yield all([
    fork(watchOrgsFetchRequest),
    fork(watchOrgsFetchIdRequest),
    fork(watchOrgsFetchSourceIdRequest),
    fork(watchOrgsCreateRequest),
    fork(watchOrgsEditRequest),
    fork(watchOrgsActiveToggleRequest),
    fork(watchOrgsOptionsParentProgrammeRequest),
    fork(watchOrgsOptionsParserRequest),
  ]);
}