import { combineReducers } from 'redux';
import auth from './auth/reducer';
import api from './api/reducer'
import metrics from './metrics/reducer'
import towers from './towers/reducer'
import theme from './theme/reducer'
import base from './base/reducer'
import orgs from './orgs/reducer'
import groups from './groups/reducer'
import max_lists from './max_lists/reducer'

const reducers = combineReducers({
  api,
  auth,
  metrics,
  towers,
  theme,
  base,
  orgs,
  groups,
  max_lists
});

export default reducers;