import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import metricsSaga from './metrics/saga'
import towersSaga from './towers/saga'
import orgsSaga from './orgs/saga'
import groupsSaga from './groups/saga'
import maxListsSaga from './max_lists/saga'

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    metricsSaga(),
    towersSaga(),
    orgsSaga(),
    groupsSaga(),
    maxListsSaga()
  ]);
}
