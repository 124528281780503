import { all, fork, takeEvery } from 'redux-saga/effects';
import { stardardRequest } from '../../utils';
import { API_BASE } from '../../constants/api.constant'

import {
  MAX_LISTS_FETCH,
  MAX_LISTS_CREATE,
  MAX_LISTS_EDIT,
  MAX_LISTS_ACTIVE_TOGGLE
} from '../actions';

export function* watchMaxListsFetchRequest() {
  const action = MAX_LISTS_FETCH
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'get',
      url: `${API_BASE}/v1/admin/max-lists/fetch`
    },
    dataPath: 'lists',
    dataDefault: [],
  }));
}

export function* watchMaxListsCreateRequest() {
  const action = MAX_LISTS_CREATE
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'post',
      url: `${API_BASE}/v1/admin/max-lists/create`
    },
    requestOptionsFromPayload: (payload) => {
      return {
        data: payload
      }
    },
    dataPath: 'lists',
    dataDefault: [],
  }));
}

export function* watchMaxListsEditRequest() {
  const action = MAX_LISTS_EDIT
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'patch',
      url: `${API_BASE}/v1/admin/max-lists/edit`
    },
    requestOptionsFromPayload: (payload) => {
      return {
        data: payload
      }
    },
    dataPath: 'max_lists',
    dataDefault: [],
  }));
}

export function* watchMaxListsActiveToggleRequest() {
  const action = MAX_LISTS_ACTIVE_TOGGLE
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'post'
    },
    requestOptionsFromPayload: (payload) => {
      return {
        url: `${API_BASE}/v1/admin/max-lists/deleted/${payload.id}`,
        data: {
          ...payload,
          id: undefined
        }
      }
    },
    dataPath: 'group',
    dataDefault: [],
  }));
}

export default function* rootSaga() {
  yield all([
    fork(watchMaxListsFetchRequest),
    fork(watchMaxListsCreateRequest),
    fork(watchMaxListsEditRequest),
    fork(watchMaxListsActiveToggleRequest),
  ]);
}